import { useEffect, useState } from 'react';
import { getCities } from '../services/api';

export default function useCities(zipCode) {
  const [cities, setCities] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function fetch() {
      if (zipCode && zipCode.length === 5) {
        setLoading(true);
        setCities(await getCities(zipCode));
        setLoading(false);
      } else {
        setCities([]);
        setLoading(false);
      }
    })();
  }, [zipCode]);

  return { loading, cities };
}
