import React from 'react';

export default function Text({ className, error, label, name, register }) {
  return (
    <div className={`input-group ${className || ''}`}>
      <input
        type="text"
        name={name}
        placeholder=" "
        className={`w-100 ${error ? 'input-error' : 'input '}`}
        ref={register}
      />
      <label htmlFor={name}>{label}</label>
      {error && <span className="mainText red xsmall">{error}</span>}
    </div>
  );
}
