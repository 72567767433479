import React from 'react';

/**
 * Do not render children if Bootstrap Modal is not displayed
 */
export default function BootstrapModalAdapter({ children, querySelector, onHide }) {
  const [isModalShown, setModalShown] = React.useState(false);

  React.useEffect(() => {
    let timer;
    const item = document.querySelector(querySelector);
    const show = () => setModalShown(true);
    const hide = () => {
      timer = setTimeout(() => {
        onHide();
        setModalShown(false);
      }, 300);
    };

    item.addEventListener('show.bs.modal', show, false);
    item.addEventListener('hide.bs.modal', hide, false);

    return () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      item.removeEventListener('show.bs.modal', show);
      item.removeEventListener('hide.bs.modal', hide);
    };
  }, [querySelector]);

  if (!isModalShown) {
    return null;
  }

  return children;
}
