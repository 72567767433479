import React from 'react';
import PropTypes from 'prop-types';

import FiltersOverlay from '../FiltersOverlay';

import { Collapse } from '../_icons';

function NeedsFilter({ filters = [] }) {
  const [selected, setSelected] = React.useState(filters[0].id);

  const handleChange = (id) => {
    setSelected(id);

    const swiper = document.querySelector('.swiper-full-width[data-id="home-needs"]');

    const slides = swiper.querySelectorAll(`.swiper-slide`);
    slides.forEach((slide) => {
      // Hide slides that do not refer to the current Need
      slide.classList.toggle('d-none', !slide.matches(`[data-needid*='${id}']`));
    });

    if (swiper && swiper.swiper) {
      swiper.swiper.update();
      swiper.swiper.slideTo(0);
    }
  };

  const title = filters.find((el) => el.id === selected)?.title ?? filters[0].title;

  return (
    <FiltersOverlay filters={filters} onChange={handleChange} selected={selected}>
      {(openFiltersOverlay) => (
        <button type="button" onClick={openFiltersOverlay}>
          <span className="mainText medium onelineText">{title}</span>
          <Collapse />
        </button>
      )}
    </FiltersOverlay>
  );
}

NeedsFilter.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

NeedsFilter.defaultProps = {
  filters: [],
};

export default NeedsFilter;
