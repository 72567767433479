import React from 'react';
import like from '../../../images/like.png';

function Success() {
  return (
    <div className="container-fluid my-5">
      <div className="row pt-5">
        <div className="col-12 pt-4">
          <img src={like} alt="success" />
        </div>
        <div className="col-12 mainText medium textHeightS fontWeightB pt-4">Merci !</div>
        <div className="col-lg-4 col-12 pt-4">
          Un de nos conseillers vous rappellera pour vous proposer une solution
          personnalisée.
        </div>
        <div className="col-12 pt-5">
          <div
            className="button dark small bordered"
            data-dismiss="modal"
            aria-label="Close"
          >
            Retour au site
          </div>
        </div>
      </div>
    </div>
  );
}

export default Success;
