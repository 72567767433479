import React from 'react';
import InputMask from 'react-input-mask';
import { useController } from 'react-hook-form';

export default function Mask({
  className,
  control,
  error,
  label,
  mask,
  name,
  rules,
  transform,
}) {
  const {
    field: { ref, onChange, ...inputProps },
  } = useController({
    name,
    control,
    rules,
    defaultValue: '',
  });

  return (
    <div className={`input-group ${className}`}>
      <InputMask
        mask={mask}
        name={name}
        className={`w-100 ${error ? 'input-error' : 'input '}`}
        placeholder=" "
        inputRef={ref}
        onChange={(e) => {
          const { value } = e.target;
          onChange(transform?.output?.(value) ?? value);
        }}
        {...inputProps}
      />

      <label htmlFor={name}>{label}</label>
      {error && <span className="mainText red xsmall">{error}</span>}
    </div>
  );
}
