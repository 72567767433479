export const API = {
  WWW: 'www',
  SOFT: 'soft',
  PRO: 'pro',
};

let proHeaders = {};

export async function graphQuery({ query, variables }, api = API.WWW) {
  const { wwwApi, softApi, proApi, wwwHeaders = {} } = window;

  let url;
  let headers = { 'Content-Type': 'application/json' };

  switch (api) {
    case API.WWW:
      url = wwwApi;
      headers = { ...headers, ...wwwHeaders };
      break;

    case API.SOFT:
      url = softApi;
      break;

    case API.PRO:
      url = proApi;
      headers = { ...headers, ...proHeaders };
      break;

    default:
      throw new Error('Bad `api` argument');
  }

  const response = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify({ query, variables }),
  });

  return response.json(); // awaitable
}

export function setProApiToken(token) {
  proHeaders = { Authorization: `Bearer ${token}` };
}
