import React from 'react';
import { render } from 'react-dom';

// eslint-disable-next-line import/prefer-default-export
export function attachNext(selector, Component) {
  document.querySelectorAll(selector).forEach((domContainer) => {
    const data = domContainer.getAttribute('data-props');
    const props = data ? JSON.parse(data) : {};
    // eslint-disable-next-line react/jsx-props-no-spreading
    render(<Component {...props} />, domContainer);
  });
}
