import { scrollTo } from './helpers';

/*
 * Animate scroll on anchor click
 */
document.querySelectorAll("a[href*='#'][class*='anchor']:not([href='#'])").forEach((el) =>
  el.addEventListener('click', (evt) => {
    if (
      location.hostname == evt.target.hostname &&
      evt.target.pathname.replace(/^\//, '') == location.pathname.replace(/^\//, '')
    ) {
      const target = document.querySelector(`[name=${evt.target.hash.slice(1)}]`);
      if (target) {
        const top =
          target.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
        scrollTo(top, 1500);
      }
    }
  })
);

/*
 * Add wrapper to all Youtube iframes to be abble to style them
 */
document.querySelectorAll("iframe[src^='https://www.youtube.com']").forEach((vid) => {
  const parent = vid.parentNode;
  const wrapper = document.createElement('div');
  wrapper.classList.add('youtube-iframe');

  parent.replaceChild(wrapper, vid);
  wrapper.appendChild(vid);
});
