import { useEffect, useState } from 'react';
import { getStores } from '../services/api';

export default function useProviderStores(provider, storeGroup) {
  const [stores, setStores] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function fetch() {
      setStores(await getStores(provider, storeGroup));
      setLoading(false);
    })();
  }, [provider, storeGroup]);

  return { loading, stores };
}
