import React from 'react';
import { useWatch } from 'react-hook-form';
import useCities from '../../hooks/useCities';

import SearchableSelect from './SearchableSelect';

export default function City({ className, control, error, label, name, rules, zipCode }) {
  const { loading, cities } = useCities(zipCode);

  const options = React.useMemo(
    () =>
      cities?.map((city) => ({
        value: city,
        label: city,
      })),
    [cities]
  );

  const value = useWatch({ control, name });

  React.useEffect(() => {
    if (options) {
      if (options.length > 0) {
        control.setValue(name, cities?.includes(value) ? value : options[0].value, {
          shouldValidate: true,
        });
      }
    }
  }, [options, name, control, value]);

  return (
    <SearchableSelect
      name={name}
      label={label}
      className={className}
      control={control}
      rules={rules}
      options={options}
      isLoading={loading}
      isDisabled={loading || !cities || cities.length <= 0}
      isClearable
      error={error}
    />
  );
}
