import React from 'react';
import PropTypes from 'prop-types';

export function Arrow({ fill, width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 95.95 88.15"
      width={width}
      height={height}
    >
      <g>
        <path
          fill={fill}
          d="M55.94,85.43,93.07,51.27c3.85-3.54,3.82-10.52,0-14.06L55.94,2.74C46.57-6,32.47,8.07,41.88,16.81L79,51.27V37.21L41.88,71.36c-9.44,8.69,4.65,22.73,14.06,14.07Z"
        />
        <path
          fill={fill}
          d="M83.7,34.14H9.61C-3.19,34.14-3.21,54,9.61,54H83.7c12.8,0,12.82-19.89,0-19.89Z"
        />
      </g>
    </svg>
  );
}

Arrow.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Arrow.defaultProps = {
  fill: '#2e2258',
  width: '15',
  height: '15',
};

// `transform` must be on `g` and not `svg` to works on iOS
export function Collapse({ up, width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 88.15 57.19"
      width={width}
      height={height}
      className={up ? 'svg-icon-180' : ''}
    >
      <g>
        <path
          fill={color}
          d="M2.72,17.19,36.88,54.32c3.54,3.85,10.52,3.82,14.06,0L85.41,17.19c8.7-9.37-5.34-23.47-14.07-14.07L36.88,40.25H50.94L16.79,3.12C8.1-6.32-5.94,7.78,2.72,17.19Z"
        />
      </g>
    </svg>
  );
}

Collapse.propTypes = {
  up: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

Collapse.defaultProps = {
  up: false,
  width: '15',
  height: '15',
  color: '#2e2258',
};

export function LongArrow({ theme, width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 95.95 88.15"
      width={width}
      height={height}
    >
      <g>
        <path
          fill={theme === 'light' ? '#FFF' : '#2e2258'}
          d="M55.94,85.43,93.07,51.27c3.85-3.54,3.82-10.52,0-14.06L55.94,2.74C46.57-6,32.47,8.07,41.88,16.81L79,51.27V37.21L41.88,71.36c-9.44,8.69,4.65,22.73,14.06,14.07Z"
        />
        <path
          fill={theme === 'light' ? '#FFF' : '#2e2258'}
          d="M83.7,34.14H9.61C-3.19,34.14-3.21,54,9.61,54H83.7c12.8,0,12.82-19.89,0-19.89Z"
        />
      </g>
    </svg>
  );
}

LongArrow.propTypes = {
  theme: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

LongArrow.defaultProps = {
  theme: 'dark',
  width: '11',
  height: '11',
};

export function Cross({ plus, width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className={plus ? 'svg-icon-45' : ''}
    >
      <g>
        <path
          fill="#2e2258"
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        />
      </g>
    </svg>
  );
}

Cross.propTypes = {
  plus: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

Cross.defaultProps = {
  plus: false,
  width: '24',
  height: '24',
};
