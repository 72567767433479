import React from 'react';

import iphoneHandyImg from '../../../images/iphone_handy.png';
import close from '../../../images/close_dark.svg';

export default function ModalHtmlWrapper({ children }) {
  return (
    <div className="modal-dialog modal-lg" role="document">
      <div className="modal-content h-100">
        <div className="modal-body">
          <div className="d-flex justify-content-end mx-2">
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                <div
                  data-dismiss="modal"
                  aria-label="Close"
                  className="button circular light bordered large_shadow"
                >
                  <img src={close} alt="close" aria-hidden="true" />
                </div>
              </div>
            </div>
          </div>

          {children}
        </div>
        <div
          className="modal-footer d-flex justify-content-center position-relative"
          style={{ border: 0, outline: 0 }}
        >
          <img
            alt=""
            src={iphoneHandyImg}
            className="position-absolute d-lg-block d-none w-50"
            style={{ right: '-150px', bottom: 0 }}
          />
        </div>
      </div>
    </div>
  );
}
