import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * fake input file, once we click, we trigger the real click on the input file
 *
 * @export
 * @class FileSelector
 * @extends {Component}
 */
export default class FileSelector extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      fileName: 'Veuillez sélectionner un fichier...',
    };
    this.realInput = document.getElementById(this.props.id);
  }

  componentDidMount() {
    if (this.realInput) {
      this.realInput.addEventListener('change', () => {
        const name = this.realInput.value.split(/\\|\//).pop();
        const truncated = name.length > 20 ? name.substr(name.length - 20) : name;

        this.setState({ fileName: truncated });
      });
    }
  }

  componentWillUnmount() {
    if (this.realInput) this.realInput.removeEventListener('change', () => {});
  }

  handleClick = () => this.realInput.click();

  render() {
    return this.props.name && this.props.label ? (
      <div className="input-group mt-3">
        <input
          type="text"
          className="input w-100 cursor-pointer upload-input"
          name={this.props.name}
          readOnly
          value={this.state.fileName}
          onClick={this.handleClick}
          placeholder=" "
        />
        <label htmlFor={this.props.name}>{this.props.label}</label>
      </div>
    ) : null;
  }
}
