/* eslint-disable no-new */

import Swiper, { Navigation, Mousewheel } from 'swiper';

const sliders = document.querySelectorAll('.swiper-full-width');

sliders.forEach((slider) => {
  const { id } = slider.dataset;
  const identifier = id ? `-${id}` : '';

  new Swiper(slider, {
    modules: [Navigation, Mousewheel],
    spaceBetween: 35,
    slidesPerView: 'auto',
    mousewheel: {
      forceToAxis: true,
    },
    navigation: {
      nextEl: `.swiper-button-next${identifier}`,
      prevEl: `.swiper-button-prev${identifier}`,
    },
  });
});
