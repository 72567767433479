import React from 'react';

export default function Checkbox({ name, label, register }) {
  return (
    <label className="my-checkbox mt-3">
      <input type="checkbox" name={name} ref={register} />
      <span>{label}</span>
    </label>
  );
}
