import React from 'react';

import SearchableSelect from './SearchableSelect';

import useProviderStores from '../../hooks/useProviderStores';

function ProviderStoreSelect({
  control,
  className,
  error,
  label,
  name,
  rules,
  provider,
  storeGroup,
}) {
  const { loading, stores } = useProviderStores(provider, storeGroup);

  return (
    <SearchableSelect
      className={className}
      control={control}
      error={error}
      isClearable
      isDisabled={loading}
      isLoading={loading}
      label={label}
      name={name}
      openMenuOnClick={false} // force user to type to open menu
      openMenuOnFocus={false} // force user to type to open menu
      hideDropdownIndicator
      options={stores}
      rules={rules}
    />
  );
}

export default ProviderStoreSelect;
