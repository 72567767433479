import React from 'react';
import PropTypes from 'prop-types';

import BootstrapModalAdapter from './components/BootstrapModalAdapter';
import ModalHtmlWrapper from './ModalHtmlWrapper';
import CallRequest from './CallRequest';
import Success from './Success';

import { sendCallRequest } from '../../services/api';

function CallRequestContainer({
  provider,
  storeGroup,
  cguLabel,
  providerStoreLabels,
  defaultValues,
}) {
  const [success, setSuccess] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      await sendCallRequest({
        ...values,
        ...(provider ? { provider } : {}),
        ...(storeGroup ? { storeGroup } : {}),
      });

      // Cozynergy variant and prod only
      if (window.google_tag_manager_env === 'PROD' && !provider) {
        window.dataLayer.push({ event: 'lead-web-call-back-adwords' });
        window.dataLayer.push({
          event: 'enhanced-tracking',
          Email: values.email,
          Phone: values.phoneNumber,
        });
      }
      window.dataLayer.push({ event: 'lead-web-call-back-analytics' });

      setSuccess(true);
    } catch {
      alert("Un problème est survenu lors de l'envoi de votre demande de rappel");
    }
  };

  const handleHide = () => {
    setSuccess(false);
  };

  return (
    <BootstrapModalAdapter querySelector="#callRequest" onHide={handleHide}>
      <ModalHtmlWrapper>
        {success ? (
          <Success />
        ) : (
          <CallRequest
            onSubmit={handleSubmit}
            provider={provider}
            storeGroup={storeGroup}
            providerStoreLabels={providerStoreLabels}
            cguLabel={cguLabel}
            defaultValues={defaultValues}
          />
        )}
      </ModalHtmlWrapper>
    </BootstrapModalAdapter>
  );
}

CallRequestContainer.defaultProps = {
  provider: null,
  storeGroup: null,
  providerStoreLabels: null,
  cguLabel: null,
};

CallRequestContainer.propTypes = {
  provider: PropTypes.string,
  storeGroup: PropTypes.string,
  providerStoreLabels: PropTypes.string,
  cguLabel: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValues: PropTypes.object,
};

export default CallRequestContainer;
