/*
 * Animate background arrows
 */

const observer = new IntersectionObserver(
  (entries, obs) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        obs.unobserve(entry.target); // Stop observing
        entry.target.classList.add('triggered'); // Trigger animation
      }
    });
  },
  {
    // Margin at bottom only, to trigger animation when arrows are well in the viewport when scrolling
    rootMargin: '0px 0px -200px 0px',
  }
);

const targets = document.querySelectorAll('.fat-arrow');
targets.forEach((target) => observer.observe(target));
